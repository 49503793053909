import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { IconSearchClear, IconSearchSearch } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { usePageContext } from '@/components/common/PageContext';
import { BreakPoint } from '@/components/styles/media';
import { a11y } from '@/components/styles/reset';
import { shouldUseHistoryNavigationSelector } from '@/features/global/globalNavigationBar';
import { isDesktopSelector } from '@/features/global/variables/variablesSlice';
import {
  instantSearchSelector,
  resetInstantSearchAction,
  updateInstantSearchAction,
} from '@/features/search/instantSearch/instantSearchSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { SearchAuthor, SearchBook } from '@/models/searchApi/Search/SearchType';
import { MAX_SEARCH_KEYWORD_LENGTH } from '@/services/searchApi/search/searchService';
import { sendClickEvent, sendSubmitEvent } from '@/utils/eventClient';
import { isNewNavigation } from '@/utils/featureFlag/genreHomeNavigation';
import { throttle } from '@/utils/functions';
import { addRDTTrackingURIQuery } from '@/utils/query';
import { decodeURLSearchRequestQuery, encodeSearchURLRequestQuery } from '@/utils/search';

import { useFeatureFlagValueByKey } from '../../FeatureFlag';
import { useAdultExclude } from '../hooks/useAdultExclude';
import { useSearchHistory } from '../hooks/useSearchHistory';
import { SearchHistory } from '../SearchHistory';
import { SearchResult } from '../SearchResult';
import * as styles from './SearchBar.styles';

const StyledSearchForm = styled.form<{ focused?: boolean }>`
  ${styles.formStyle};
  ${({ focused }) => focused && styles.formFocusedStyle};
`;

const StyledSearchBoxWrapper = styled.div<{ focused?: boolean; navigationFeatureFlag?: boolean }>`
  ${styles.searchBoxWrapperStyle};
  ${({ focused, theme }) => focused && styles.searchBoxWrapperFocusedStyle(theme)};
  ${({ focused, navigationFeatureFlag }) => !focused && navigationFeatureFlag && styles.searchBoxWrapperUnfocusedStyle};
`;

const StyledSearchPopupWrapper = styled.div<{ focused: boolean }>`
  ${({ theme }) => styles.popupWrapperStyle(theme)};
  ${({ focused }) => focused && styles.popupWrapperFocusedStyle};
`;

interface SearchBarProps {
  className?: string;
  focusedCss?: SerializedStyles;
  isExperimentalPage: boolean;
}

export interface SearchBarHandle {
  focus: () => void;
}

export const SearchBar = forwardRef(
  ({ className, focusedCss, isExperimentalPage }: SearchBarProps, ref): ReactJSX.Element => {
    const pageContext = usePageContext();
    const router = useRouter();
    const isFocusingEnabled = useSelector(isDesktopSelector);
    const searchQuery = useMemo(() => decodeURLSearchRequestQuery(router.query), [router.query]);

    const [keyword, setKeyword] = useState(searchQuery.keyword ?? '');
    const [isAdultExcluded, setIsAdultExcluded] = useAdultExclude();
    const [isFocused, setIsFocused] = useState(false);
    const [focusedIndexRaw, setFocusedIndexRaw] = useState<number | null>(null);

    const focusedIndex = isFocusingEnabled ? focusedIndexRaw : null;
    const setFocusedIndex = useCallback(
      (newFocusedIndex: number | null) => {
        if (isFocusingEnabled) {
          setFocusedIndexRaw(newFocusedIndex);
        }
      },
      [isFocusingEnabled],
    );

    const { result } = useSelector(instantSearchSelector);
    const authorsCount = result ? result.author.authors.length : 0;
    const booksCount = result ? result.book.books.length : 0;

    const history = useSearchHistory();
    const itemsCount = result ? authorsCount + booksCount : history.history.length;

    const goToInstantUrl = useCallback((url: string) => {
      window.location.href = url;
    }, []);

    const shouldUseHistoryNavigation = useSelector(shouldUseHistoryNavigationSelector);
    const goToSearchResult = useCallback(
      (searchKeyword: string) => {
        const trimmedKeyword = searchKeyword.trim();
        if (!trimmedKeyword) {
          return;
        }

        const params = encodeSearchURLRequestQuery({
          keyword: trimmedKeyword,
          isAdultExcluded,
        });
        const url = `/search?${params}`;

        history.addHistory(trimmedKeyword);

        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        if (shouldUseHistoryNavigation) {
          router.push(url);
          return;
        }

        window.location.href = url;
      },
      [router, isAdultExcluded, history, shouldUseHistoryNavigation],
    );

    const defaultInstantParams = useMemo<string>(() => {
      const params = new URLSearchParams({
        _s: 'instant',
        _q: keyword,
      });

      return params.toString();
    }, [keyword]);

    const getAuthorUrl = useCallback(
      (author: SearchAuthor) => `/author/${author.id}?${defaultInstantParams}`,
      [defaultInstantParams],
    );
    const getBookUrl = useCallback(
      (book: SearchBook, index: number) =>
        addRDTTrackingURIQuery(`/books/${book.b_id}?${defaultInstantParams}`, {
          sectionId: 'search_instant',
          sectionItemIdx: index,
          sectionArg: keyword,
        }),
      [defaultInstantParams, keyword],
    );

    // 위 아래키로 선택 이동
    const onKeyDown = (event: KeyboardEvent) => {
      let newPosition = focusedIndex ?? itemsCount;

      switch (event.key) {
        case 'ArrowDown':
        case 'Down':
          newPosition += 1;
          break;

        case 'ArrowUp':
        case 'Up':
          newPosition -= 1;
          break;

        case 'Enter':
          // submit 이벤트 호출을 막음
          break;

        default:
          setFocusedIndex(null);
          return;
      }

      event.preventDefault();
      event.stopPropagation();

      newPosition += itemsCount + 1;
      newPosition %= itemsCount + 1;
      setFocusedIndex(newPosition === itemsCount ? null : newPosition);
    };

    // 엔터키로 검색
    // > IME Composition이 끝난 후 호출되어야 하므로 keydown에서 하지 않고 keyup에서 함
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      // 무언가 선택이 된 상태, 가능하다면 빠른 검색을 수행
      if (focusedIndex != null) {
        // 히스토리 선택
        if (keyword === '') {
          const historyKeyword = history.history[focusedIndex];
          sendSubmitEvent(pageContext.screenName, 'search_recent_item', {
            ...pageContext.params,
            keyword: historyKeyword,
          });
          goToSearchResult(historyKeyword);
          return;
        }

        // 작가 선택
        if (result && focusedIndex < authorsCount) {
          const author = result.author.authors[focusedIndex];
          sendSubmitEvent(pageContext.screenName, 'search_instant_author_item', {
            ...pageContext.params,
            item_id: author.id,
            item_name: author.name,
            keyword,
          });
          goToInstantUrl(getAuthorUrl(author));
          return;
        }

        // 책 선택
        if (result && focusedIndex < itemsCount) {
          const book = result.book.books[focusedIndex - authorsCount];
          sendSubmitEvent(pageContext.screenName, 'search_instant_book_item', {
            ...pageContext.params,
            item_id: book.b_id,
            item_name: book.web_title_title,
            keyword,
          });
          goToInstantUrl(getBookUrl(book, focusedIndex));
          return;
        }
      }

      // 일반 검색을 수행
      if (keyword !== '') {
        sendSubmitEvent(pageContext.screenName, 'search', {
          ...pageContext.params,
          keyword,
        });
        goToSearchResult(keyword);
      }
    };

    // Submit으로 검색
    // > 이 때에는 선택이 되어 있더라도 빠른 검색이 아닌 일반 검색을 수행해야함
    const onSubmit = (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      sendSubmitEvent(pageContext.screenName, 'search', { ...pageContext.params, keyword });
      goToSearchResult(keyword);
    };

    // focus 여부 트래킹
    const onFocus = () => {
      sendClickEvent(pageContext.screenName, 'search', pageContext.params);
      setIsFocused(true);
    };

    const onBlur = (event: FocusEvent) => {
      const relatedTarget = event.relatedTarget || document.activeElement;
      if (!event.currentTarget.contains(relatedTarget)) {
        setIsFocused(false);
      }
      setFocusedIndex(null);
    };

    // 포커스 명령형 핸들 설정
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
    }));

    // 기타 이벤트 핸들러
    const onKeywordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setKeyword(value);
    const onKeywordResetClick = () => {
      setKeyword('');
      inputRef.current?.focus();
    };

    const onMouseLeave = () => setFocusedIndex(null);
    const onCollapseClick = () => setIsFocused(false);
    const onBackdropClick = (event: MouseEvent<HTMLFormElement>) => {
      if (event.target !== event.currentTarget) {
        return;
      }

      setIsFocused(false);
    };

    // 검색어가 바뀔 때 검색 실행
    const dispatch = useAppDispatch();

    const throttledSearch = useMemo(
      () =>
        throttle<(keyword: string, isAdultExcluded: boolean) => void>((searchKeyword, isAdultExcludedSearch) => {
          dispatch(
            updateInstantSearchAction({
              reqParams: { keyword: searchKeyword, isAdultExcluded: isAdultExcludedSearch },
            }),
          );
        }, 1000),
      [dispatch],
    );

    useEffect(() => {
      if (keyword.trim() === '') {
        dispatch(resetInstantSearchAction());
        return;
      }

      throttledSearch(keyword, isAdultExcluded);
    }, [dispatch, throttledSearch, keyword, isAdultExcluded]);

    // 본문 스크롤 막기
    // 여기에서 overflow만 막고, iOS 용으로 formFocusedStyle에서 touch-action을 막음
    const isOverlayEnabled = useResponsiveIsBelow(BreakPoint.DesktopSmall);
    useEffect(() => {
      const previousOverflow = document.body.style.overflow;

      if (isOverlayEnabled && isFocused) {
        document.body.style.overflow = 'hidden';

        return () => {
          document.body.style.overflow = previousOverflow;
        };
      }

      return () => {};
    }, [isOverlayEnabled, isFocused]);

    const searchPopup =
      isFocused &&
      (keyword === '' || !result || itemsCount === 0 ? (
        <SearchHistory
          css={styles.popupContentStyle}
          history={history}
          goToSearchResult={goToSearchResult}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
        />
      ) : (
        <SearchResult
          css={styles.popupContentStyle}
          keyword={keyword}
          getAuthorUrl={getAuthorUrl}
          getBookUrl={getBookUrl}
          goToInstantUrl={goToInstantUrl}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          isAdultExcluded={isAdultExcluded}
          setIsAdultExcluded={setIsAdultExcluded}
        />
      ));

    const navigationFeatureFlag = isNewNavigation(
      useFeatureFlagValueByKey('web-genre-home-navigation-20240903'),
      isExperimentalPage,
    );

    return (
      <>
        <StyledSearchForm
          className={className}
          css={isFocused && focusedCss}
          focused={isFocused}
          role="search"
          onClick={onBackdropClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onSubmit={onSubmit}
          onMouseLeave={onMouseLeave}>
          {/* button이 포커스를 가지지 못하는 사파리에서 지우기 버튼 누를 시,
        focusTrap이 포커스를 먹게 하여 검색창이 닫히는 것을 방지 */}
          <div css={styles.focusTrapStyle} tabIndex={-1}>
            <StyledSearchBoxWrapper focused={isFocused} navigationFeatureFlag={navigationFeatureFlag}>
              <label css={navigationFeatureFlag ? styles.searchBoxShape2Style : styles.searchBoxShapeStyle}>
                <IconSearchSearch css={navigationFeatureFlag ? styles.searchIcon2Style : styles.searchIconStyle} />
                <span css={a11y}>인스턴트 검색</span>

                <input
                  ref={inputRef}
                  css={styles.searchBoxStyle}
                  type="text"
                  value={keyword}
                  onChange={onKeywordChange}
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  maxLength={MAX_SEARCH_KEYWORD_LENGTH}
                  tabIndex={-1}
                />

                {isFocused && keyword !== '' && (
                  <TrackClickEvent
                    screenName={pageContext.screenName}
                    target="search_reset_keyword"
                    params={{ ...pageContext.params, keyword }}>
                    <button type="button" onClick={onKeywordResetClick} css={styles.resetButtonStyle}>
                      <IconSearchClear css={styles.clearStyle} aria-label="지우기" />
                    </button>
                  </TrackClickEvent>
                )}
              </label>

              {isFocused && (
                <TrackClickEvent screenName={pageContext.screenName} target="search_cancel" params={pageContext.params}>
                  <button type="button" css={styles.cancelButtonStyle} onClick={onCollapseClick}>
                    취소
                  </button>
                </TrackClickEvent>
              )}
            </StyledSearchBoxWrapper>
            {searchPopup && <StyledSearchPopupWrapper focused={isFocused}>{searchPopup}</StyledSearchPopupWrapper>}
          </div>
        </StyledSearchForm>
        {isFocused && <div css={navigationFeatureFlag ? styles.formPlaceholder2Style : styles.formPlaceholderStyle} />}
      </>
    );
  },
);
