import { useTheme } from '@emotion/react';
import { BreakPoint } from '@ridi-web/design-system/Styles';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  IconBadge2X,
  IconDelete,
  IconGNBCart,
  IconGNBCash,
  IconGNBCategory,
  IconGNBLibrary,
  IconGNBMyRidi,
  IconGNBNotification,
  IconLogoRIDI,
  IconLogoRIDIAB,
  IconSearchSearch,
} from '@/assets/svgs/system';
import { useFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { SearchBar, SearchBarHandle } from '@/components/common/Search';
import { a11y } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';
import { cartCountSelector, fetchCartCountAction } from '@/features/cart/cartSlice';
import {
  currentGlobalNavigationSelector,
  currentNavigationRouteSelector,
  genreChangeSheetSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import {
  fetchScheduleEventsAction,
  scheduleEventsSelector,
} from '@/features/global/globalNavigationBar/scheduleEvents/scheduleEventsSlice';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';
import { fetchUnreadCountAction, unreadCountSelector } from '@/features/notification/notificationSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { useRouteFetchEffect } from '@/hooks/useRouteFetchEffect';
import { GenreChangeSheet } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { isNewNavigation } from '@/utils/featureFlag/genreHomeNavigation';
import { getStorageItemJSON, LOCAL_STORAGE_KEYS, setStorageItemJSON } from '@/utils/storage';

import { TrackClickEvent } from '../EventClient/TrackClickEvent';
import { usePageContext } from '../PageContext/PageContextProvider';
import * as styles from './GlobalNavigationBar.styles';
import { GlobalNavigationBarLink } from './GlobalNavigationBarLink';
import { GlobalNavigationBarBottom, GlobalNavigationBarTop } from './GlobalNavigationBarTop';

export const getGenreChangeSheet = (theme: RIDITheme['colorScheme'], genreChangeSheet?: GenreChangeSheet['web']) => {
  if (!genreChangeSheet) {
    return null;
  }

  return genreChangeSheet[theme] ?? genreChangeSheet['light' as const];
};

export const getLogoImageUrl = (
  theme: RIDITheme['colorScheme'],
  findingTitle?: string,
  genreChangeSheet?: GenreChangeSheet['web'],
) => {
  if (!findingTitle) {
    return null;
  }

  const genreChangeSheetWeb = getGenreChangeSheet(theme, genreChangeSheet);
  return genreChangeSheetWeb?.find(({ title }) => title === findingTitle)?.image_url;
};

interface GNBLogoProps {
  isInExperimentPage: boolean;
}

const GNBLogo = ({ isInExperimentPage }: GNBLogoProps): ReactJSX.Element => {
  const { screenName } = usePageContext();
  const theme = useTheme();
  const themeColor = theme.colorScheme;
  const genreChangeSheet = useSelector(genreChangeSheetSelector);
  const currentGlobalNavigation = useSelector(currentGlobalNavigationSelector);

  const GNBRidiLogo = useMemo(() => {
    const targetTitle = '리디';
    const logoImageUrl = getLogoImageUrl(themeColor, targetTitle, genreChangeSheet);

    if (logoImageUrl) {
      return (
        <div css={styles.logoStyle}>
          <img css={styles.logoImageStyle} src={logoImageUrl} alt={targetTitle} />
        </div>
      );
    }

    return <IconLogoRIDI css={styles.logoStyle} />;
  }, [genreChangeSheet, themeColor]);

  const navigationFeatureFlag = isNewNavigation(
    useFeatureFlagValueByKey('web-genre-home-navigation-20240903'),
    isInExperimentPage,
  );

  const GNBGenreLogo = useMemo(() => {
    const logoImageUrl = getLogoImageUrl(themeColor, currentGlobalNavigation?.title, genreChangeSheet);

    if (logoImageUrl) {
      return (
        <div css={styles.logoGenreIconStyle}>
          <img css={styles.logoImageStyle} src={logoImageUrl} alt={currentGlobalNavigation?.title} />
        </div>
      );
    }

    return <></>;
  }, [currentGlobalNavigation?.title, genreChangeSheet, themeColor]);

  return navigationFeatureFlag ? (
    <GlobalNavigationBarLink
      css={styles.logoAnchorStyle2}
      href="/"
      trackingParams={{
        screenName,
        target: 'ridibooks',
      }}>
      <IconLogoRIDIAB css={styles.logoImageStyleAB} />
      <span css={a11y}>리디 홈으로 이동</span>
    </GlobalNavigationBarLink>
  ) : (
    <div css={styles.logoWrapperStyle}>
      <GlobalNavigationBarLink
        css={styles.logoAnchorStyle}
        href="/"
        trackingParams={{
          screenName,
          target: 'ridibooks',
        }}>
        <div css={styles.logoWrapperStyle}>
          {GNBRidiLogo}
          {GNBGenreLogo}
        </div>
        <span css={a11y}>리디 홈으로 이동</span>
      </GlobalNavigationBarLink>
    </div>
  );
};

interface GNBButtonLinkProps {
  className?: string;
  children: ReactNode;
  path: string;
  label: string;
  target: string;
  addOn?: ReactNode;
}

const GNBButtonLink = ({ className, children, path, label, target, addOn }: GNBButtonLinkProps) => {
  const pageContext = usePageContext();

  return (
    <div css={styles.buttonItemStyle} className={className}>
      <TrackClickEvent screenName={pageContext.screenName} target={target} params={pageContext.params}>
        <a css={styles.buttonAnchorStyle} href={path}>
          <span css={a11y}>{label}</span>
          {children}
          {addOn}
        </a>
      </TrackClickEvent>
    </div>
  );
};

const GNBCoinButton = () => {
  const user = useLoggedUser();
  const eventStatus = useSelector(scheduleEventsSelector).events;
  const doubleCashFeatureFlag = useFeatureFlagValueByKey('web-genre-home-navigation-double-cash-20240912');
  const isDoublePointDay = !!doubleCashFeatureFlag || !!eventStatus?.double_point;

  return (
    <GNBButtonLink
      css={styles.cashButtonStyle}
      label="캐시충전"
      path="/order/checkout/cash"
      target="cash"
      addOn={user && isDoublePointDay && <div css={styles.cashAddOnStyle}>2X</div>}>
      <IconGNBCash />
    </GNBButtonLink>
  );
};

const GNBNotificationButton = () => {
  const user = useLoggedUser();
  const router = useRouter();
  const unreadCount = useSelector(unreadCountSelector);

  const isInNotification = router.pathname === '/notification';

  return (
    <GNBButtonLink
      label="알림"
      path="/notification"
      target="notification"
      addOn={
        !!user &&
        !!unreadCount &&
        !isInNotification && (
          <div css={styles.notificationAddOnStyle}>
            <span css={a11y}>{unreadCount}개 읽지 않음</span>
          </div>
        )
      }>
      <IconGNBNotification />
    </GNBButtonLink>
  );
};

const GNBCartButton = () => {
  const user = useLoggedUser();
  const cartCount = useSelector(cartCountSelector);

  return (
    <GNBButtonLink
      label="카트"
      path="/cart"
      target="cart"
      addOn={
        !!user &&
        !!cartCount && (
          <div css={styles.cartAddOnStyle}>
            {cartCount}
            <span css={a11y}>개의 아이템</span>
          </div>
        )
      }>
      <IconGNBCart />
    </GNBButtonLink>
  );
};

const GNBMyRIDIButton = () => (
  <GNBButtonLink label="마이리디" path="/account/myridi" target="myridi">
    <IconGNBMyRidi />
  </GNBButtonLink>
);

const GNBLibraryButton = () => (
  <GNBButtonLink label="내 서재" path="/library" target="library">
    <IconGNBLibrary />
  </GNBButtonLink>
);

const GNBSearchButton = ({ className, onClick }: { className?: string; onClick: () => void }) => {
  const pageContext = usePageContext();

  return (
    <div className={className} css={styles.buttonItemStyle}>
      <TrackClickEvent screenName={pageContext.screenName} target="search" params={pageContext.params}>
        <button type="button" css={styles.buttonContentStyle} onClick={onClick}>
          <span css={a11y}>검색</span>
          <IconSearchSearch />
        </button>
      </TrackClickEvent>
    </div>
  );
};

interface GNBLoginButtonProps {
  isInExperimentPage: boolean;
}

const GNBLoginButton = ({ isInExperimentPage }: GNBLoginButtonProps) => {
  const router = useRouter();
  const pageContext = usePageContext();
  const [returnURL, setReturnURL] = useState('');
  const navigationFeatureFlag = isNewNavigation(
    useFeatureFlagValueByKey('web-genre-home-navigation-20240903'),
    isInExperimentPage,
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('return_url', new URL(router.asPath, window.location.href).toString() || window.location.href);
    setReturnURL(`/account/login?${params.toString()}`);
  }, [router.asPath]);

  return (
    <TrackClickEvent screenName={pageContext.screenName} target="login" params={pageContext.params}>
      <a css={navigationFeatureFlag ? styles.loginButtonStyleAB : styles.loginButtonStyle} href={returnURL}>
        로그인
      </a>
    </TrackClickEvent>
  );
};

const GNBRegisterButton = () => {
  const router = useRouter();
  const pageContext = usePageContext();
  const [returnURL, setReturnURL] = useState('');

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('return_url', new URL(router.asPath, window.location.href).toString() || window.location.href);
    setReturnURL(`/account/signup?${params.toString()}`);
  }, [router.asPath]);

  return (
    <TrackClickEvent screenName={pageContext.screenName} target="register" params={pageContext.params}>
      <a css={styles.registerButtonStyle} href={returnURL}>
        회원가입
      </a>
    </TrackClickEvent>
  );
};

const GNBButtons = ({
  children,
  className,
  isInExperimentPage,
}: {
  children?: ReactNode;
  className?: string;
  isInExperimentPage: boolean;
}) => {
  const user = useLoggedUser();
  const isHydrationNeeded = useSelector(isHydrateNeededSelector);
  const navigationFeatureFlag = isNewNavigation(
    useFeatureFlagValueByKey('web-genre-home-navigation-20240903'),
    isInExperimentPage,
  );

  return (
    <div className={className} css={navigationFeatureFlag ? styles.buttonsWrapperStyleAB : styles.buttonsWrapperStyle}>
      <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileHideStyle(isHydrationNeeded)]}>{children}</div>

      <div
        css={[
          (styles.buttonsGroupStyle,
          navigationFeatureFlag
            ? styles.buttonsGroupMobileHideStyleAB(isHydrationNeeded || !user)
            : styles.buttonsGroupMobileHideStyle(isHydrationNeeded || !user)),
        ]}>
        {navigationFeatureFlag && <GNBCoinButton />}
        <GNBNotificationButton />
        <GNBCartButton />
        <GNBLibraryButton />
        <GNBMyRIDIButton />
      </div>

      {navigationFeatureFlag ? (
        <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileHideStyleAB(isHydrationNeeded || Boolean(user))]}>
          <GNBRegisterButton />
          <svg
            css={styles.loginDotStyle}
            width="3"
            height="3"
            viewBox="0 0 3 3"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.5" cy="1.5" r="1.5" />
          </svg>
          <GNBLoginButton isInExperimentPage={isInExperimentPage} />
        </div>
      ) : (
        <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileShowStyle(!isHydrationNeeded && !user)]}>
          <GNBLoginButton isInExperimentPage={isInExperimentPage} />
        </div>
      )}
    </div>
  );
};

// 123 기간에 숨긴다면 다음 123기간까지는 숨겨지도록 (> 3, <= 25)
const CASHCHARGE_TOOLTIP_DISMISS_PERIOD = 1000 * 60 * 60 * 24 * 10;
const GNBCashChargeTooltip = () => {
  const pageContext = usePageContext();
  const user = useLoggedUser();
  const eventStatus = useSelector(scheduleEventsSelector).events;
  const [hasDismissed, setHasDismissed] = useState<boolean | null>(null);

  useEffect(() => {
    const dismissedAt = getStorageItemJSON('local', LOCAL_STORAGE_KEYS.CASHCHARGE_TOOLTIP_DISMISSED_AT);
    if (!dismissedAt) {
      setHasDismissed(false);
      return;
    }

    setHasDismissed(dismissedAt + CASHCHARGE_TOOLTIP_DISMISS_PERIOD > Date.now());
  }, []);

  const onDismiss = () => {
    setStorageItemJSON('local', LOCAL_STORAGE_KEYS.CASHCHARGE_TOOLTIP_DISMISSED_AT, Date.now());
    setHasDismissed(true);
  };

  const shouldShowTooltip = hasDismissed === false && eventStatus?.double_point && user;
  return (
    <TrackClickEvent screenName={pageContext.screenName} target="charge_cash_tooltip" params={pageContext.params}>
      <button
        css={[styles.cashChargeTooltipStyle, !shouldShowTooltip && styles.cashChargeTooltipHideStyle]}
        type="button"
        onClick={onDismiss}>
        <span css={styles.cashChargeTooltipTextStyle}>캐시충전은 MY 안에 있어요!</span>

        <IconDelete css={styles.cashChargeTooltipIconStyle} aria-label="닫기" />
      </button>
    </TrackClickEvent>
  );
};

interface GlobalNavigationBarProps {
  className?: string;
  isPartial?: boolean;
  showSearch?: boolean;
  showMyRIDITooltip?: boolean;
  isSearchPage?: boolean;
}

export const GlobalNavigationBar = ({
  className,
  isPartial,
  showSearch = false,
  showMyRIDITooltip = false,
  isSearchPage = false,
}: GlobalNavigationBarProps): ReactJSX.Element => {
  const isInApp = useSelector(isRidiAppSelector);
  const user = useLoggedUser();
  const searchBarHandle = useRef<SearchBarHandle | null>(null);
  const onOpenSearch = useCallback(() => {
    if (searchBarHandle.current) {
      searchBarHandle.current.focus();
    }
  }, []);

  const dispatch = useAppDispatch();
  const shouldFetchUserData = !!user;
  const fetchData = useCallback(async () => {
    const fetchList: Promise<unknown>[] = [];
    fetchList.push(dispatch(fetchScheduleEventsAction({})));

    if (shouldFetchUserData) {
      fetchList.push(dispatch(fetchCartCountAction({})));
      fetchList.push(dispatch(fetchUnreadCountAction({})));
    }

    await Promise.all(fetchList);
  }, [dispatch, shouldFetchUserData]);
  useRouteFetchEffect(fetchData, [fetchData]);

  const pageContext = usePageContext();
  const featureFlagValue = useFeatureFlagValueByKey('web-genre-home-navigation-20240903');
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);
  const isGenreHomeRoute = !!currentNavigationRoute?.at(-1)?.resource_url;
  const isInExperimentPage = !isPartial && isGenreHomeRoute;
  const navigationFeatureFlag = isNewNavigation(featureFlagValue, isInExperimentPage);

  const events = useSelector(scheduleEventsSelector);
  const doubleCashFeatureFlag = useFeatureFlagValueByKey('web-genre-home-navigation-double-cash-20240912');
  const isDoublePointDay = !!doubleCashFeatureFlag || !!events.events?.double_point;
  const [hideBgGradation, setHideBgGradation] = useState(false);

  const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall);

  if (isInApp) {
    return <></>;
  }

  return (
    <>
      {!navigationFeatureFlag && <GlobalNavigationBarTop />}
      <div css={styles.wrapperStyle} className={className}>
        <header css={styles.containerStyle}>
          {navigationFeatureFlag ? (
            <nav css={styles.navigationStyleAB}>
              <GNBLogo isInExperimentPage={isInExperimentPage} />
              <SearchBar
                css={[styles.searchBarStyleAB, !showSearch && styles.searchBarHideStyle]}
                focusedCss={styles.searchBarShow2Style}
                ref={searchBarHandle}
                isExperimentalPage={isInExperimentPage}
              />
              <GNBButtons isInExperimentPage={isInExperimentPage}>
                {!showSearch && <GNBSearchButton css={styles.searchButtonStyle} onClick={onOpenSearch} />}
                {showMyRIDITooltip && <GNBCashChargeTooltip />}
              </GNBButtons>
            </nav>
          ) : (
            <nav css={styles.navigationStyle}>
              <GNBLogo isInExperimentPage={isInExperimentPage} />
              <>
                <SearchBar
                  css={[styles.searchBarStyle, !showSearch && styles.searchBarHideStyle]}
                  focusedCss={styles.searchBarShowStyle}
                  ref={searchBarHandle}
                  isExperimentalPage={isInExperimentPage}
                />
                <GNBButtons isInExperimentPage={isInExperimentPage}>
                  {!showSearch && <GNBSearchButton css={styles.searchButtonStyle} onClick={onOpenSearch} />}
                  {showMyRIDITooltip && <GNBCashChargeTooltip />}
                </GNBButtons>
              </>
            </nav>
          )}
          {navigationFeatureFlag && (
            <div css={[styles.genreContainerStyle, !showSearch && styles.genreContainerHiddenStyle]}>
              {!(isMobile && isSearchPage) && (
                <GlobalNavigationBarBottom
                  onScrollRightEnd={setHideBgGradation}
                  isGenreHome={isGenreHomeRoute}
                  isPartial={isPartial}
                />
              )}
              <div css={styles.allCategoryContainerStyle}>
                <div css={styles.bgGradientStyle} aria-hidden={hideBgGradation} />
                {!(isMobile && isSearchPage) && (
                  <TrackClickEvent
                    screenName={pageContext.screenName}
                    target="all_categories"
                    params={pageContext.params}>
                    <a href="/category/list" css={styles.allCategoriesLinkStyle}>
                      <IconGNBCategory css={styles.allCategoriesIconStyle} />
                      <div css={styles.allCategoriesClickableOverlay} />
                      <span css={styles.allCategoriesTextStyle}>전체 카테고리</span>
                    </a>
                  </TrackClickEvent>
                )}
                <TrackClickEvent screenName={pageContext.screenName} target="charge_cash" params={pageContext.params}>
                  <a css={styles.cashStyle} href="/order/checkout/cash">
                    {isDoublePointDay && (
                      <IconBadge2X css={styles.iconBadge2XStyle} aria-label="리디 캐시 더블 포인트 충전" />
                    )}
                    <span css={styles.cashChargeTextStyle}>캐시충전</span>
                  </a>
                </TrackClickEvent>
              </div>
            </div>
          )}
        </header>
      </div>
      {navigationFeatureFlag && <hr css={[styles.hrStyle, !isGenreHomeRoute && styles.hrMobileStyle]} />}
    </>
  );
};
