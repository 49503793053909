import { css, SerializedStyles } from '@emotion/react';

import { pageContainerStyle, ZIndexLayer } from '@/components/styles';
import { BreakPoint, greaterThan, nonTouchDevice, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const globalNavigationBarTopStyle = css`
  position: relative;
`;

export const globalNavigationBarTopHiddenStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const topBarStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.bgBase};
  border-bottom: 1px solid ${theme.colors.grey200};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: ${theme.colors.black};
      border-color: ${theme.colors.black};
    `,
  )};
`;

export const topItemsWrapperStyle = css`
  ${pageContainerStyle};

  display: flex;
  padding: 0 18px;
`;

export const topItemsStyle = css`
  display: flex;
  flex: 1;
`;

export const topItemsStyleAB = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: auto;

  color: ${theme.colors.fillPrimary};
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.01em;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 15px;
      padding-right: 4px;
      line-height: 20px;
    `,
  )};
`;

export const topSecondaryItemsStyle = css`
  display: flex;
  flex: 0 0 auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const topItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    width: 3px;
    height: 3px;
    margin-top: 3px;
    border-radius: 3px;
    background: ${theme.colors.grey300};
  }

  &:last-of-type::after {
    display: none;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex: 1;

      &::after {
        display: none;
      }
    `,
  )};
`;

export const topItem2Style = css`
  padding: 17px 12px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 8px 12px 11px;
    `,
  )};
`;

export const topItemLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  padding: 12px;
  padding-top: 11px;
  padding-bottom: 10px;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillSecondary};
  transition: opacity 0.2s ease;

  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}

  &:link,
  &:visited {
    // FIXME overriding books-backend's page_base_renewal.css when /partial
    color: ${theme.colors.fillSecondary};
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      color: ${theme.colors.white};
      padding-top: 12px;
      padding-bottom: 10px;
      line-height: 17px;
      padding-left: 1px;
      padding-right: 0;

      &:link,
      &:visited {
        // FIXME overriding books-backend's page_base_renewal.css when /partial
        color: ${theme.colors.white};
      }
    `,
  )};
`;

export const topItemLinkStyleAB = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;

  padding: 17px 12px;

  color: ${theme.colors.fillSecondary};
  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 9px 9px 11px;
    `,
  )};
`;

export const topItemSelectedLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  cursor: default;
  color: ${theme.colors.fillPrimary};
  pointer-events: none;
  touch-action: none;

  &:link,
  &:visited {
    // FIXME overriding books-backend's page_base_renewal.css when /partial
    color: ${theme.colors.fillPrimary};
  }

  &:hover {
    opacity: 1;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      &:link,
      &:visited {
        // FIXME overriding books-backend's page_base_renewal.css when /partial
        color: ${theme.colors.white};
      }
      opacity: 1;
    `,
  )};
`;

export const topItemSelectedLinkStyleAB = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  font-weight: 700;
  pointer-events: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
`;

export const topItemLinkIconStyle = css`
  font-size: 18px;
  align-self: flex-end;
  margin-left: 2px;
`;

export const topItemExternalLinkStyle = css`
  display: inline-flex;
  align-items: center;

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      width: 11px;
      font-size: 11px;
      justify-content: center;
      margin-left: 1px;
    `,
  )};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 9px;
      margin-left: 1px;
    `,
  )};
`;

export const topPlaceholderStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 40px;
    `,
  )};
`;

export const markerStyle = css`
  width: 100%;
  height: 0;
`;

export const maskStyle = (theme: RIDITheme): SerializedStyles => css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: sticky;
      justify-content: space-between;
      display: none;

      top: 0;
      width: 100%;
      height: 16px;
      margin-bottom: -16px;
      overflow: hidden;
      z-index: ${ZIndexLayer.LEVEL_OVERLAY};

      &::before,
      &::after {
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      &::before {
        box-shadow: -16px -16px 0 0 ${theme.colors.black};
      }

      &::after {
        box-shadow: 16px -16px 0 0 ${theme.colors.black};
      }

      @supports (position: sticky) {
        display: flex;
      }
    `,
  )}
`;

export const disableClickWhileHydrationStyle = css`
  pointer-events: none;
`;

export const topItemLinkGenreHomeStyleAB = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillPrimary};
`;

export const topItemLinkSubPageStyleAB = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
`;
